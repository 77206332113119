.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}





.main-slider .swiper-slide {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-slider .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  height: 100vh;
  -webkit-transform: scale(1);
  transform: scale(1);
  /* -webkit-transition: -webkit-transform 7000ms ease;
  transition: -webkit-transform 7000ms ease;
  transition: transform 7000ms ease;
  transition: transform 7000ms ease, -webkit-transform 7000ms ease; */
}

.main-slider .container {
  position: relative;
  z-index: 10;
  padding-top: 70px;
}

.main-slider__title {
  white-space: pre-line;
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.15em;
  font-weight: 700;
  margin: 0;
  margin-bottom: 30px;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(90px);
  transform: translateY(90px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

@media (min-width: 768px) {
  .main-slider__title {
    font-size: 50px;
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .main-slider__title {
    margin-bottom: 40px;
    font-size: 50px;
  }

}

@media (min-width: 1200px) {
  .main-slider__title {
    font-size: 70px;
  }
}

@media (max-width:902px) {
  .main-slider__subtext {
    font-size: 12px !important;
  }
}

@media (max-width:300px) {
  .main-slider__subtext {
    font-size: 8px !important;
  }
}

.main-slider__subtext {
  width: 50%;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  opacity: 0;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 0;
  transition-delay: 0;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

.main-slider .common_btn {
  margin-top: 30px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
}

.main-slider .swiper-slide-active .image-layer {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.main-slider .swiper-slide-active .common_btn,
.main-slider .swiper-slide-active .main-slider__title,
.main-slider .swiper-slide-active .main-slider__subtext {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider [class*="swiper-button-"]::after {
  display: none;
}

.main-slider [class*="swiper-button-"] {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.35);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000000;
  font-size: 24px;
  transition: all 500ms ease;
  display: none;
  border-radius: 50px;
}

.main-slider [class*="swiper-button-"]:hover {
  background-color: #fff;
}


@media (min-width: 768px) {
  .main-slider [class*="swiper-button-"] {
    width: 70px;
    height: 70px;
    display: flex;
  }
}

@media (min-width: 768px) {
  .main-slider .swiper-button-next {
    right: 30px;
  }

  .main-slider .swiper-button-prev {
    left: 30px;
  }
}

@media (min-width: 1200px) {
  .main-slider .swiper-button-next {
    right: 60px;
  }

  .main-slider .swiper-button-prev {
    left: 60px;
  }
}

.main-slider__two .main-slider__subtext,
.main-slider__two .main-slider__title {
  color: #000000;
}

@media (max-width: 992px) {
  .vertical-social {
    display: none !important;
  }
}


.vertical-social {
  position: fixed;
  top: -4.2rem;
  transform: rotate(90deg);
  left: -100px;

  background: #0d003b;
  height: 200px !important;
  margin-left: 30px;
  border-radius: 40px;
  padding: 30px 15px;
  z-index: 999;
  transition: 1s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0px 4px 13px 0 rgba(168, 67, 253, .3);
  overflow: hidden;
  border-bottom: 3px solid #a843fd;
}

.vertical-social li {
  padding: 7px 0;
  text-align: center;
}

.vertical-social li a {
  color: #fff;
  opacity: .6;
  font-size: 22px;
}

.vertical-social li a:hover {
  opacity: 1
}

.scrolling-image-container {
  margin: 0 4rem;
}

@media (max-width: 992px) {
  .scrolling-image-container {
    width: 100%;
    overflow-x: scroll;
    /* Enable horizontal scrolling */
    white-space: nowrap;
    /* Prevent line breaks */
    margin: 0;
  }

  .scrolling-image {
    min-width: 250%;
    height: auto;
    overflow-x: scroll;
  }
}

pre {
  white-space: pre-wrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.image-footer {
  max-width: 12rem;
}

#calendarContainer {
  min-height: 100rem !important;
}

.navbarArrowMain {
  display: none !important;
}

@media only screen and (min-width: 991px) and (max-width : 1300px) {
  .navbarArrow {
    display: none !important;
  }

  .navbarArrowMain {
    display: block !important;
  }

  .secondArrow {
    position: absolute !important;
    left: auto !important;
    right: 0px !important;
  }

  .secondArrow2 {
    position: absolute !important;
    left: auto !important;
    right: 180px !important;
  }
}

.navbarIcon {
  position: absolute;
  top: 25px;
  scale: 1.4;
}

.menu-item.flex.items-center .language-select-box {
  display: none;
}

@media (max-width: 992px) {
  .menu-item.flex.items-center .language-select-box {
    display: block !important;
  }

  .has-submenu.parent-menu-item .language-select-box {
    display: none;
  }

  .mobile-slider-image {
    /* display: none!important; */
    height: 80vh !important;
    filter: opacity(0.1);
  }

  .main-slider__subtext {
    width: 100% !important;
  }

  .main-slider .swiper-slide {
    height: 70vh !important;
  }

}

@media (max-width: 600px) {
  .ad-popup-inner {
    width: 90% !important;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .ad-popup-inner {
    width: 60% !important;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .ad-popup-inner {
    width: 50% !important;
  }
}

.ad-popup {
  position: fixed;
  z-index: 123213;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-popup-inner {
  position: relative;
  border-radius: 10px;
  text-align: end;
  width: 50%;
}

.ad-popup-inner img {
  max-width: 80%;
  height: auto;
  border-radius: 5px;
  margin: auto;
}

.ad-popup-inner-close-btn{
  position: absolute;
  top: 10px;
  right: 90px;
}

.ad-popup-inner button {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px){
  .ad-popup-inner img {
    max-width: 100%;
    border-radius: 5px;
    margin: auto;
  }
  .ad-popup-inner-close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}